.card {
    text-align: center;
    border: 1px solid #121212;
    height: 300px;
    margin: 25px auto;
}

.card .title {
    text-transform: uppercase;
}

.card .subtitle {
    text-transform: uppercase;
}

.question {
    font-size: large;
}